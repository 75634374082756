import React, { useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { useFormik } from 'formik';
import classNames from 'classnames';

import { urls, useRequest } from '../../../../Common/ApiServices';
import {
  editImagesFormSchema,
  initialEditImagesFormSchema,
} from './editImagesFormvalidation';

import Image from '../../../../Common/Image/Image';

import styles from './EditImagesForm.module.scss';

const EditImagesForm = ({
  displayEditImageModal,
  images,
  header,
  onHide,
  logId,
  logType,
  fieldId,
  fieldName,
  imageCount,
  setLogs,
}) => {
  const { isLoading, sendRequest } = useRequest({});

  const addImageRef = useRef(null);

  const beforeFormClose = () => {
    setLogs((prevLogs) =>
      prevLogs.map((log) => {
        if (log.id === logId) {
          return {
            ...log,
            [fieldName]: formik.values.images,
          };
        }
        return log;
      })
    );
    onHide();
  };

  const extractLog = async (logId, sort = false) => {
    const data = {
      log: {
        id: {
          is: logId,
        },
      },
    };
    const requestData = {
      url: urls.EXTRACT_LOG,
      method: 'POST',
      data: data,
    };

    const response = await sendRequest(requestData);

    if (response) {
      const updatedField = response.data.log.fields.find(
        (field) => field.id === fieldId
      );

      const newImages = sort
        ? updatedField.images.sort(
            (a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)
          )
        : updatedField.images;
      await formik.setFieldValue('images', newImages);
    }
  };

  const createLogImage = async (image) => {
    const formData = new FormData();
    formData.append('logId', logId);
    formData.append('fieldId', fieldId);
    formData.append('file', image);

    const requestData = {
      url:
        logType === 'EVENT'
          ? urls.CREATE_EVENT_LOG_IMAGE
          : urls.CREATE_LOG_IMAGE,
      method: 'POST',
      data: formData,
    };

    const response = await sendRequest(requestData);
    if (response) {
      await extractLog(logId, true);
    }
  };

  const updateLogImage = async (fieldId, imageId, image) => {
    const formData = new FormData();
    formData.append('imageId', imageId);
    formData.append('file', image);

    const requestData = {
      url:
        logType === 'EVENT'
          ? urls.UPDATE_EVENT_LOG_IMAGE
          : urls.UPDATE_LOG_IMAGE,
      method: 'POST',
      data: formData,
    };

    const response = await sendRequest(requestData);
    if (response) {
      await extractLog(logId);
    }
  };

  const deleteLogImage = async (imageId) => {
    const requestData = {
      url:
        logType === 'EVENT'
          ? urls.DELETE_EVENT_LOG_IMAGES
          : urls.DELETE_LOG_IMAGES,
      method: 'POST',
      data: {
        logId: logId,
        imageId: {
          in: [imageId],
        },
      },
    };

    await sendRequest(requestData);
  };

  const onImageCreate = () => {
    addImageRef.current.click();
  };

  const deleteImage = async (selectedImage) => {
    const filteredImages = formik.values.images.filter(
      (img) => img.id !== selectedImage.id
    );

    await deleteLogImage(selectedImage.id);
    await formik.setFieldValue('images', filteredImages);
  };

  const createImage = (event, isDragAndDrop) => {
    const file = isDragAndDrop
      ? event.dataTransfer.files[0]
      : event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        createLogImage(file, fieldId);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateImage = (file, selectedImage) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        updateLogImage(fieldId, selectedImage.id, file);
      };
      reader.readAsDataURL(file);
    }
  };

  const formik = useFormik({
    initialValues: initialEditImagesFormSchema(images),
    enableReinitialize: true,
    validationSchema: editImagesFormSchema,
    onSubmit: async (values) => {
      beforeFormClose();
    },
  });

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      createImage(event, true);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Dialog
      className={styles.editImageForm}
      header={header}
      visible={displayEditImageModal}
      onHide={onHide}
    >
      <div className={styles.images}>
        <div className={styles.header}>
          <Button
            type="button"
            icon="pi pi-images"
            className={classNames(
              'p-button-outlined p-button-rounded p-ml-auto'
            )}
            onClick={onImageCreate}
            disabled={formik.values.images.length >= imageCount}
          />
          <input
            className={styles.hidden}
            ref={addImageRef}
            type="file"
            onChange={(e) => createImage(e, false)}
            onClick={(event) => {
              event.target.value = null;
            }}
            accept=".jpeg,.jpg,.png,.webp"
          />
          {isLoading && (
            <div
              className={classNames('p-d-flex p-jc-end', styles.progressBar)}
            >
              <ProgressBar
                className={classNames('p-ml-5 p-as-center', styles.progress)}
                mode="indeterminate"
              />
            </div>
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.content}>
            {formik.values.images?.length ? (
              formik.values.images.map((img) => (
                <Image
                  key={img.id}
                  image={img}
                  deleteImage={deleteImage}
                  updateImage={updateImage}
                />
              ))
            ) : (
              <div
                className={classNames(
                  'p-d-flex p-ai-center p-dir-col',
                  styles.emptyTemplate
                )}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <i className="pi pi-image p-mt-1 p-p-3" />
                <span className="p-my-2">Drag and Drop Image Here</span>
              </div>
            )}
            <div className={styles.buttons}>
              <Button
                className={styles.button}
                label="Save"
                type="submit"
                icon="pi pi-check"
                autoFocus
              />
              <Button
                className={`p-button-secondary ${styles.button}`}
                label="Cancel"
                type="submit"
                icon="pi pi-times"
                onClick={beforeFormClose}
              />
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default EditImagesForm;
