import React, { useState } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import classNames from 'classnames';

import BackDrop from '../BackDrop/BackDrop';

import styles from './ZoomGallery.module.scss';

const ZoomGallery = ({ close, images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  return (
    <div className={styles.zoomGallery}>
      <BackDrop close={close} />
      <div className={styles.galleryContainer}>
        <InnerImageZoom
          src={selectedImage.full}
          zoomScale={2}
          zoomType="hover"
        />
        <div className={styles.imageMenu}>
          {images.map((image) => {
            return (
              <div
                className={classNames(
                  styles.imageItem,
                  image.id === selectedImage.full && styles.active
                )}
                key={image.id}
                onClick={() => setSelectedImage(image)}
              >
                <img src={image.full} alt="" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ZoomGallery;
