import * as yup from 'yup';
import logoPlaceholder from '../../../assets/img/logo-placeholder.jpg';

export const initialCompanyFormSchema = (companyInfo) => {
  return {
    logoId: companyInfo.logo ? companyInfo.logo.id : null,
    logo: companyInfo.logo ? companyInfo.logo.thumbnail : logoPlaceholder,
    name: companyInfo.name || '',
    email: companyInfo.email || '',
    address: companyInfo.address,
    address2: companyInfo.address2 || '',
    city: companyInfo.city || '',
    state: companyInfo.state,
    postalCode: companyInfo.postalCode || '',
    firstDay: companyInfo?.firstDayOfWeek || '',
    country: companyInfo.country,
    phoneNumber: companyInfo.phoneNumber || '',
    webAddress: companyInfo.webAddress || '',
  };
};

export const companyFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Company Name should be provided.')
    .max(50, 'Maximum length exceeded.'),
  email: yup
    .string()
    .email('Invalid email format.')
    .max(255, 'Maximum length exceeded.'),
  address: yup
    .string()
    .required('Address Line 1 should be provided.')
    .max(512, 'Maximum length exceeded.'),
  address2: yup.string().max(128, 'Maximum length exceeded.'),
  city: yup
    .string()
    .required('City should be provided.')
    .max(255, 'Maximum length exceeded.'),
  state: yup.object().shape({
    code: yup.string().required(),
    name: yup.string().required('State/Province should be provided'),
  }),
  postalCode: yup
    .string()
    .required('Postal Code should be provided.')
    .max(32, 'Maximum length exceeded.'),
  country: yup.object().shape({
    code: yup.string().required(),
    name: yup.string().required('Country should be provided'),
  }),
  phoneNumber: yup.string().max(15, 'Maximum length exceeded.'),
  webAddress: yup.string().max(100, 'Maximum length exceeded.'),
});
