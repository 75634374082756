import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'primereact/button';
import classNames from 'classnames';

import styles from './Image.module.scss';

const Image = ({ image, deleteImage, updateImage }) => {
  const updateImageRef = useRef(null);

  const onImageUpdate = () => {
    updateImageRef.current.click();
  };

  return (
    <div className={styles.image}>
      <img src={image.full} alt="" />
      <div className={styles.buttons}>
        <Button
          type="button"
          icon="pi pi-images"
          label="Update"
          onClick={onImageUpdate}
          className={classNames('p-button-outlined p-button-rounded')}
        />
        <input
          className={styles.hidden}
          name="update"
          ref={updateImageRef}
          type="file"
          onChange={(e) => updateImage(e.target.files[0], image)}
          onClick={(event) => {
            event.target.value = null;
          }}
          accept=".jpeg,.jpg,.png, .webp"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className={classNames(
            styles.dangerButton,
            'p-button-outlined p-button-rounded'
          )}
          onClick={() => deleteImage(image)}
        />
      </div>
    </div>
  );
};

export default Image;
