import React, { useState } from 'react';
import { Button } from 'primereact/button';

import ZoomGallery from '../../../Common/ZoomGallery/ZoomGallery';
import EditImagesForm from './EditImagesForm/EditImagesForm';

import styles from './LogImages.module.scss';

const LogImages = ({
  images,
  header,
  isEditing,
  logId,
  logType,
  fieldId,
  fieldName,
  trialFields,
  setLogs,
}) => {
  const [displayEditImageModal, setDisplayEditImageModal] = useState(false);
  const [showZoomGallery, setShowZoomGallery] = useState('');

  const onHide = () => {
    setDisplayEditImageModal(false);
  };

  const imageCount = (trialFields, fieldId) => {
    return trialFields?.find((field) => field.id === fieldId)?.imageCount;
  };

  return (
    <div className={styles.logImages}>
      {showZoomGallery && (
        <ZoomGallery close={setShowZoomGallery} images={images} />
      )}

      <div className={styles.logImages}>
        {images &&
          images.map((image, index) => {
            let imgEl = (
              <img
                className={styles.logImage}
                src={image.thumbnail}
                alt={image.alt}
                style={{ cursor: 'pointer' }}
                onClick={() => setShowZoomGallery(image)}
              />
            );
            return <div key={index}>{imgEl}</div>;
          })}
        {isEditing && (
          <Button
            icon="pi pi-pencil"
            className={`p-button-rounded p-button-info ${styles.pencilButton}`}
            onClick={() => {
              setDisplayEditImageModal(true);
            }}
          />
        )}
      </div>
      {displayEditImageModal && (
        <EditImagesForm
          displayEditImageModal={displayEditImageModal}
          onHide={onHide}
          images={images}
          header={header}
          logId={logId}
          logType={logType}
          fieldId={fieldId}
          fieldName={fieldName}
          imageCount={imageCount(trialFields, fieldId)}
          setLogs={setLogs}
        />
      )}
    </div>
  );
};

export default LogImages;
