import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import DatePicker from 'datepicker-special-week-numbers';
import classNames from 'classnames';

import ImageField from './ImageField/ImageField';

import styles from './DynamicField.module.scss';

const DynamicField = ({
  trialField,
  formik,
  isImageLoading,
  createLogImage,
  deleteLogImage,
  updateLogImage,
}) => {
  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const CustomCalendarInput = forwardRef(
    ({ icon, icon2 = null, value, onClick, error, touch }, ref) => (
      <Button
        label={value}
        className={classNames(
          error && formik.touched[touch]
            ? styles.calendarButtonError
            : styles.calendarButton,
          'p-button-text p-button-plain'
        )}
        onClick={onClick}
        onBlur={() => {
          formik.handleBlur({ target: { name: touch } });
        }}
        ref={ref}
        type="button"
      >
        <i className={`pi ${icon}`} />
        {icon2 && <i className={`pi ml-1 ${icon2}`} />}
      </Button>
    )
  );

  const renderInputField = (fieldType) => {
    switch (fieldType) {
      case 'TEXT':
        return (
          <InputText
            id={trialField.name}
            type="text"
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            onChange={formik.handleChange}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            value={formik.values[trialField.name]}
          />
        );
      case 'NOTE':
        return (
          <InputTextarea
            id={trialField.name}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            type="text"
            rows="4"
            onChange={formik.handleChange}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            value={formik.values[trialField.name]}
          />
        );
      case 'INTEGER':
        return (
          <InputNumber
            id={trialField.name}
            min={trialField.minValue ? trialField.minValue : 0}
            max={trialField.maxValue ? trialField.maxValue : 100000}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            onChange={(e) => formik.setFieldValue(trialField.name, e.value)}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            value={formik.values[trialField.name]}
          />
        );
      case 'NUMBER':
        return (
          <InputNumber
            id={trialField.name}
            min={trialField.minValue ? trialField.minValue : 0}
            max={trialField.maxValue ? trialField.maxValue : 100000}
            minFractionDigits={2}
            maxFractionDigits={5}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            onChange={(e) => formik.setFieldValue(trialField.name, e.value)}
            value={formik.values[trialField.name]}
          />
        );
      case 'DATE':
        return (
          <DatePicker
            id={trialField.name}
            onChange={(val) => {
              formik.setFieldValue(trialField.name, val);
            }}
            selected={formik.values[trialField.name]}
            weekLabel={'Wk'}
            className={styles.datePicker}
            showWeekNumbers
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            customInput={
              <CustomCalendarInput
                icon="pi pi-calendar"
                error={formik?.errors[trialField.name]}
                touch={trialField.name}
              />
            }
          />
        );
      case 'TIME':
        return (
          <DatePicker
            id={trialField.name}
            className={classNames(
              styles.calendarButton,
              isTrialTracker && styles.disabledStyle
            )}
            onChange={(val) => {
              formik.setFieldValue(trialField.name, val);
            }}
            selected={formik?.values[trialField.name]}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            aria-describedby={trialField.name}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            dateFormat="h:mm a"
            timeFormat="h:mm a"
            customInput={
              <CustomCalendarInput
                icon="pi pi-clock"
                error={formik?.errors[trialField.name]}
                touch={trialField.name}
              />
            }
          />
        );
      case 'DATE_TIME':
        return (
          <DatePicker
            id={trialField.name}
            onChange={(val) => {
              formik.setFieldValue(trialField.name, val);
            }}
            selected={formik.values[trialField.name]}
            aria-describedby={trialField.name}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
            customInput={
              <CustomCalendarInput
                icon="pi pi-calendar"
                icon2="pi pi-clock"
                error={formik?.errors[trialField.name]}
                touch={trialField.name}
              />
            }
          />
        );
      case 'CHECKBOX':
        return (
          <Checkbox
            id={trialField.name}
            onChange={formik.handleChange}
            value={formik.values[trialField.name]}
            checked={formik.values[trialField.name]}
          />
        );
      case 'SELECT': {
        return trialField.multiselect ? (
          <MultiSelect
            id={trialField.name}
            optionLabel="name"
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            options={trialField.values || []}
            onChange={formik.handleChange}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            value={formik.values[trialField.name]}
          />
        ) : (
          <Dropdown
            id={trialField.name}
            optionLabel="name"
            options={trialField.values || []}
            onChange={formik.handleChange}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            value={formik.values[trialField.name]}
          />
        );
      }
      case 'IMAGE':
        return (
          <ImageField
            trialField={trialField}
            formik={formik}
            isImageLoading={isImageLoading}
            createLogImage={createLogImage}
            deleteLogImage={deleteLogImage}
            updateLogImage={updateLogImage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {
        <div
          className={classNames(
            'p-field p-grid p-ai-start',
            styles.dynamicField
          )}
        >
          <label
            htmlFor="plant"
            className={classNames(
              'p-col-12 p-md-3',
              trialField.required && 'p - text - bold'
            )}
          >
            {trialField.required ? `${trialField.label}*` : trialField.label}
          </label>
          <div className={classNames('p-col-12 p-md-9', styles.inputZone)}>
            {renderInputField(trialField.type)}
            {formik.touched[trialField.name] && formik.errors[trialField.name] && (
              <small
                id={`${trialField.name}-invalid`}
                className="p-error p-d-block"
              >
                {formik.errors[trialField.name]}
              </small>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default DynamicField;
