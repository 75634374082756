import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { urls, useRequest } from '../../../Common/ApiServices';
import { getTrialFilters } from '../../../../reduxStore/trialFilters/actions';
import LogFilters from '../LogFilters/LogFilters';
import LogList from '../LogList/LogList';
import LeftBar from '../../../LeftBar/LeftBar';
import { isAuth } from '../../../../auth/auth-service';
import { useParams } from 'react-router-dom';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import { popUp, errorCodes } from '../../../Common/globalConstants';
import { getTrialFieldParams } from '../LogList/constants';

const LogPage = () => {
  const { id } = useParams();

  const [filters, setFilters] = useState(null);
  const [firstDay, setFirstDay] = useState(null);
  const [dateFormat, setDateFormat] = useState(null);
  const [trialFields, setTrialFields] = useState([]);
  const [fieldsTypes, setFieldTypes] = useState({});

  const sections = useSelector((state) => state.trialFilters?.sections);
  const programs = useSelector((state) => state.trialFilters?.programs);
  const genuses = useSelector((state) => state.trialFilters?.genuses);
  const series = useSelector((state) => state.trialFilters?.series);
  const trialFiltersReady = useSelector(
    (state) => state.trialFilters?.trialFiltersReady
  );

  const { error, sendRequest } = useRequest({});

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const extractTrial = async () => {
    const trialParams = {
      query: {
        trial: {
          id: {
            is: id,
          },
        },
      },
    };

    const requestData = {
      url: urls.EXTRACT_TRIAL,
      method: 'POST',
      data: trialParams,
    };
    const response = await sendRequest(requestData);

    setFirstDay(response?.data?.location?.firstDayOfWeek?.day);
    setDateFormat(response?.data?.location?.dateFormat?.datePattern);

    return response;
  };

  const fetchTrialFields = async () => {
    const requestData = {
      url: urls.SEARCH_TRIAL_FIELDS,
      method: 'POST',
      data: getTrialFieldParams(id),
    };
    const response = await sendRequest(requestData);
    if (response) {
      const fields = response.data.results.map((rawField) => ({
        id: rawField.trialField.id,
        name: rawField.trialField.name,
        type: rawField.trialField.type,
        eventType: rawField.trialField.eventType,
        values: rawField.trialField.values,
        multiselect: rawField.trialField.multiselect,
        imageCount: rawField.trialField.imageCount || 0,
      }));

      let fieldTypes = {};
      fields.forEach((field) => {
        fieldTypes[field.name] = field.type;
      });

      setFieldTypes(fieldTypes);
      setTrialFields(fields);
    }
    return response;
  };

  const onApplyNewFilters = (
    plantDate,
    logDate,
    sections,
    programs,
    genuses,
    series
  ) => {
    setFilters({
      plantDate: plantDate,
      logDate: logDate,
      sections: sections && sections.length > 0 ? sections : null,
      programs: programs && programs.length > 0 ? programs : null,
      genuses: genuses && genuses.length > 0 ? genuses : null,
      series: series && series.length > 0 ? series : null,
    });
  };

  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  useEffect(() => {
    !trialFiltersReady && dispatch(getTrialFilters(actionData));
  }, [trialFiltersReady]);

  useEffect(() => {
    extractTrial();
    fetchTrialFields();
  }, []);

  useEffect(() => {
    error &&
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,
          summary: popUp.summary.ERROR,
          detail: errorCodes.DEFAULT_MESSAGE.text,
          life: 5000,
        })
      );
  }, [dispatch, error]);

  return (
    <React.Fragment>
      <LeftBar>
        <LogFilters
          firstDay={firstDay}
          dateFormat={dateFormat}
          sections={sections}
          programs={programs}
          genuses={genuses}
          series={series}
          applyNewFilters={onApplyNewFilters}
        />
      </LeftBar>
      <LogList
        newFilters={filters}
        fieldsTypes={fieldsTypes}
        trialFields={trialFields}
        trialId={id}
      />
    </React.Fragment>
  );
};

export default LogPage;
