import * as yup from 'yup';

export const initialEditImagesFormSchema = (values) => {
  return {
    images: values ? [...values] : [],
  };
};

export const editImagesFormSchema = yup.object().shape({
  images: yup.array().of(
    yup.object().shape({
      id: yup.string().required('Image ID is required'),
      full: yup
        .string()
        .url('Image must be a valid URL')
        .required('Image is required'),
      thumbnail: yup
        .string()
        .url('Thumbnail must be a valid URL')
        .required('Thumbnail is required'),
    })
  ),
});
