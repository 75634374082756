import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import classNames from 'classnames';

import Image from '../../../../../Common/Image/Image';

import styles from './ImageField.module.scss';

const ImageField = ({
  trialField,
  formik,
  isImageLoading,
  createLogImage,
  deleteLogImage,
  updateLogImage,
}) => {
  const addImageRef = useRef(null);

  const createImage = (event, isDragAndDrop) => {
    const file = isDragAndDrop
      ? event.dataTransfer.files[0]
      : event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        createLogImage(file, trialField);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateImage = (file, selectedImage) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const updatedArray = formik.values[trialField.name].map((img) => {
          if (img.id === selectedImage.id) {
            return {
              id: selectedImage.id,
              full: e.target.result,
              thumbnail: e.target.result,
            };
          }
          return img;
        });
        updateLogImage(trialField, selectedImage.id, file);
        formik.setFieldValue(trialField.name, updatedArray);
      };
      reader.readAsDataURL(file);
    }
  };

  const deleteImage = (selectedImage) => {
    const filteredImages = formik.values[trialField.name].filter(
      (img) => img.id !== selectedImage.id
    );

    deleteLogImage(trialField.id, selectedImage.id);
    formik.setFieldValue(trialField.name, filteredImages);
  };

  const onImageCreate = () => {
    addImageRef.current.click();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      createImage(event, true);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const onImageTouch = () => {
    formik.handleBlur({ target: { name: trialField.name } });
  };

  return (
    <div
      className={classNames(
        styles.imageField,
        formik.touched[trialField.name] &&
          formik.errors[trialField.name] &&
          styles.invalid
      )}
      onClick={onImageTouch}
    >
      <div className={styles.header}>
        <Button
          type="button"
          icon="pi pi-images"
          className={classNames('p-button-outlined p-button-rounded p-ml-auto')}
          onClick={onImageCreate}
          disabled={
            formik.values[trialField.name]?.length >= trialField.imageCount
          }
        />
        <input
          className={styles.hidden}
          ref={addImageRef}
          type="file"
          onChange={(e) => createImage(e, false)}
          onClick={(event) => {
            event.target.value = null;
          }}
          accept=".jpeg,.jpg,.png,.webp"
        />
        {isImageLoading && (
          <div className={classNames('p-d-flex p-jc-end', styles.progressBar)}>
            <ProgressBar
              className={classNames('p-ml-5 p-as-center', styles.progress)}
              mode="indeterminate"
            />
          </div>
        )}
      </div>
      <div className={styles.content}>
        {formik.values[trialField.name]?.length ? (
          formik.values[trialField.name].map((img) => (
            <Image
              key={img.id}
              image={img}
              deleteImage={deleteImage}
              updateImage={updateImage}
            />
          ))
        ) : (
          <div
            className={classNames(
              'p-d-flex p-ai-center p-dir-col',
              styles.emptyTemplate
            )}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <i className="pi pi-image p-mt-1 p-p-3" />
            <span className="p-my-2">Drag and Drop Image Here</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageField;
